import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { graphql } from 'gatsby';
import SanityBlockContent from '@sanity/block-content-to-react';

import {
  container,
  containerPage,
  item,
  itemPage,
} from '../utils/framer-motion-config';
import { serializers } from '../utils/serializer';

import { WorkPin, WorkPinSVG } from '../assets/icons/WorkPin';
import { BlueDot, BlueDotSVG } from '../assets/icons/BlueDot';
import TableImage from '../assets/images/ActivityCardTable.png';
import {
  AdvertisementPin,
  AdvertisementPinSVG,
} from '../assets/icons/AdvertisementPin';
import MichaelImage from '../assets/images/ActivityCardMichael.png';
import {
  RedDotBigRedCirle,
  RedDotBigRedCirleSVG,
} from '../assets/icons/RedDotBigRedCirle';
import {
  RedDotRedCirleSmall,
  RedDotRedCirleSmallSVG,
} from '../assets/icons/RedDotRedCirleSmall';

import { Header } from '../components/Header';
import BackgroundMap from '../components/BackgroundMap';
import { TextSection } from '../components/TextSection';
import { ActivityCard } from '../components/ActivityCard';
import { TextContainer } from '../components/TextContainer';
import { ImageMapUpsideDown } from '../components/BackgroundMapUpsideDown';

export default function AboutPage({ data }) {
  const sanityContent = data.aboutpage.contentSections;
  const LastPartOfSanityContent = sanityContent.slice(1);

  return (
    <AboutUsContainer variants={containerPage} initial="hidden" animate="show">
      <ImageMapUpsideDown />

      <AboutUsContent variants={itemPage}>
        <TextContainer>
          {sanityContent[0] && (
            <TextSection bigBottomMargin>
              <Header big bolder>
                {sanityContent[0].title}
              </Header>
              <SanityBlockContent
                blocks={sanityContent[0]._rawText}
                serializers={serializers}
                projectId={process.env.SANITY_PROJECT_ID}
                dataset={process.env.SANITY_DATASET}
              />
            </TextSection>
          )}

          {LastPartOfSanityContent.map((content, index) => (
            <TextSection bigBottomMargin key={index}>
              <Header>{content.title}</Header>
              <SanityBlockContent
                blocks={content._rawText}
                serializers={serializers}
                projectId={process.env.SANITY_PROJECT_ID}
                dataset={process.env.SANITY_DATASET}
              />
            </TextSection>
          ))}
        </TextContainer>
      </AboutUsContent>

      <BackgroundMapAboutUs
        variants={container}
        image
        overlay
        color="var(--lightGrey)"
      >
        <BlueDot size={58} variants={item} />
        <RedDotRedCirleSmall size={50} variants={item} />
        <RedDotBigRedCirle size={34} variants={item} />
        <AdvertisementPin variants={item} />
        <WorkPin variants={item} />
        <ActivityCardMichael src={MichaelImage} alt="" variants={item} />
        <ActivityCardTable src={TableImage} alt="" variants={item} />
      </BackgroundMapAboutUs>
    </AboutUsContainer>
  );
}

export const query = graphql`
  query AboutQuery {
    aboutpage: sanityPage(id: { eq: "-2f55bd2a-a591-5763-8866-1892c745f539" }) {
      contentSections {
        ... on SanityTextSection {
          title
          subtitle
          _rawText
        }
      }
    }
  }
`;

const ActivityCardMichael = styled(ActivityCard)``;

const ActivityCardTable = styled(ActivityCard)``;

const AboutUsContainer = styled(motion.div)`
  background-color: var(--lightGrey);
`;

const AboutUsContent = styled(motion.div)`
  max-width: var(--gridWidth);
  padding: 3rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 60;
  position: relative;

  @media (min-width: 1024px) {
    width: 100%;
    max-width: var(--gridWidth);
    margin: 0 auto 0 auto;
    padding: 8.6rem 3.4rem 0 3.4rem;
  }
`;

const BackgroundMapAboutUs = styled(BackgroundMap)`
  ${AdvertisementPinSVG} {
    height: auto;
    width: 60px;
    z-index: 10;
    grid-column: 4;
    grid-row: 6;
  }

  ${RedDotBigRedCirleSVG} {
    z-index: 10;
    grid-column: 2;
    grid-row: 6;
  }

  ${WorkPinSVG} {
    z-index: 10;
    height: auto;
    width: 60px;
    grid-column: 2;
    grid-row: 4;
  }

  ${ActivityCardMichael} {
    z-index: 10;
    grid-column: 6 / 14;
    grid-row: 1 / 3;
    margin-left: 4rem;
  }

  ${ActivityCardTable} {
    z-index: 10;
    grid-column: 3 / 9;
    grid-row: 9 / 12;
  }

  ${RedDotRedCirleSmallSVG} {
    z-index: 10;
    grid-column: 10;
    grid-row: 6;
  }

  ${BlueDotSVG} {
    z-index: 10;
    grid-column: 8;
    grid-row: 8;
  }

  @media (min-width: 1080px) {
    ${AdvertisementPinSVG} {
      height: auto;
      width: 98px;
      z-index: 10;
      grid-column: 2;
      grid-row: 5;
    }

    ${WorkPinSVG} {
      height: auto;
      width: 71px;
      grid-column: 11;
      grid-row: 4;
    }

    ${ActivityCardMichael} {
      z-index: 11;
      grid-column: 4 / 7;
      grid-row: 2 / 4;
      margin-left: 0;
    }

    ${ActivityCardTable} {
      z-index: 10;
      grid-column: 6 / 10;
      grid-row: 6 / 10;
    }

    ${RedDotRedCirleSmallSVG} {
      grid-column: 4;
      grid-row: 12;
    }

    ${RedDotBigRedCirleSVG} {
      grid-column: 13;
      grid-row: 3;
    }

    ${BlueDotSVG} {
      grid-column: 3;
      grid-row: 10;
    }
  }
`;
